import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import CookieConsent from "react-cookie-consent"

import Footer from "./footer.js"
import Header from "./header"

import "./layout.css"

const Layout = ({ imageUrl, pageTitle, subTitle, children }) => {
  const handleDeclineClick = () => {
    document.cookie = `ga-disable-${process.env.GA_TRACKING_ID}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/`
    window[`ga-disable-${process.env.GA_TRACKING_ID}`] = true
  }
  return (
    <div>
      <CookieConsent
        location={"bottom"}
        style={{
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
        contentStyle={{ display: "flex", margin: "5px" }}
        buttonStyle={{
          border: "0.1em solid white",
          backgroundColor: "transparent",
          color: "white",
          fontFamily: "Raleway",
        }}
        declineButtonStyle={{
          backgroundColor: "white",
          border: "0.1em solid black",
          color: "black",
          fontFamily: "Raleway",
        }}
        buttonText="Accept"
        enableDeclineButton="true"
        declineButtonText="Decline"
        flipButtons="true"
        onDecline={handleDeclineClick}
        cookieName="gatsby-gdpr-google-analytics"
      >
        <p>
          This site uses third-party cookies to analyse web traffic. By
          continuing to browse or clicking accept you agree to the storing of
          first and third-party cookies on your device to enhance site
          navigation and analyse site usage
        </p>
      </CookieConsent>
      <Header imageUrl={imageUrl} pageTitle={pageTitle} subTitle={subTitle} />
      <div className="content">{children}</div>
      <Footer />
    </div>
  )
}
export default Layout
