import * as React from "react"

import Nav from "./nav.js"

import "./header.css"

const Header = ({ imageUrl, pageTitle, subTitle = null }) => {
  return (
    <div
      className="header-container"
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      <Nav />
      <div className="header-content">
        <div className="page-title">{pageTitle}</div>
        {subTitle && <div className="page-sub-title">{subTitle}</div>}
      </div>
    </div>
  )
}

export default Header
