import * as React from "react"
import { GiDividedSquare } from "@react-icons/all-files/gi/GiDividedSquare"
import "./footer.css"

import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram"
import { AiOutlineFacebook } from "@react-icons/all-files/ai/AiOutlineFacebook"
import { StaticImage } from "gatsby-plugin-image"

const Footer = props => {
  return (
    <div className="footer">
      <div className="divider">
        <StaticImage
          src="../images/logo.png"
          width={40}
          alt="logo"
          placeholder="blurred"
          layout="fixed"
        ></StaticImage>
      </div>
      <div className="footer-content">
        <div>
          <h3> Where We Are </h3>
          <p>
            Old Court Wedding Company <br />
            Strangford <br />
            Co. Down <br />
            Northern Ireland <br />
            BT30 7NB
          </p>
        </div>

        <div>
          <h3> Connect with us </h3>
          <a href="tel:00447706992945"> (+44)7706992945 </a>
          <br />
          <br />
          <a href="mailto:info@oldcourtweddingcompany.co.uk">
            info@oldcourtweddingcompany.co.uk
          </a>
          <div className="social-links">
            <a
              href="https://www.facebook.com/oldcourtweddingcompany"
              target="_blank"
              rel="noreferrer"
              className="facebook"
            >
              <AiOutlineFacebook size="2em" />
            </a>
            <a
              href="https://www.instagram.com/explore/locations/1000072381/old-court-wedding-company/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineInstagram size="2em" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
