import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { HiMenu } from "@react-icons/all-files/hi/HiMenu"
import { HiOutlineX } from "@react-icons/all-files/hi/HiOutlineX"

import useWindowSize from "../utils/useWindowSize"

import "./nav.css"
import { StaticImage } from "gatsby-plugin-image"

const MobileNav = () => {
  const isBrowser = typeof window !== "undefined"

  const pathname = isBrowser ? window.location.pathname : "/"
  const [isOpen, setIsOpen] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(pathname)

  const handleOpenClick = () => {
    setIsOpen(true)
  }

  const handleCloseClick = () => {
    setIsOpen(false)
  }

  const navigateTo = url => {
    if (url === currentLocation) {
      handleCloseClick()
    }
    setCurrentLocation(url)
    navigate(url)
  }

  if (isOpen) {
    return (
      <div className="overlay">
        <button onClick={handleCloseClick} className="mobile-menu-button">
          <HiOutlineX />
        </button>
        <div className="overlay-content">
          <ul className="menu-items_mobile">
            <li className="menu-item">
              <a
                onClick={() => navigateTo("/")}
                onKeyDown={() => navigateTo("/")}
                href={"/"}
              >
                Home
              </a>
            </li>
            <li className="menu-item">
              <a
                onClick={() => navigateTo("/gallery")}
                onKeyDown={() => navigateTo("/gallery")}
                href={"/gallery"}
              >
                Gallery
              </a>
            </li>
            <li className="menu-item">
              <a
                onClick={() => navigateTo("/pricing")}
                onKeyDown={() => navigateTo("/pricing")}
                href={"/pricing"}
              >
                Pricing
              </a>
            </li>
            <li className="menu-item">
              <a
                onClick={() => navigateTo("/contact")}
                onKeyDown={() => navigateTo("/contact")}
                href={"/contact"}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
  return (
    <button onClick={handleOpenClick} className="mobile-menu-button">
      <HiMenu />
    </button>
  )
}

const DesktopNav = () => {
  return (
    <nav className="navbar">
      <div className="logo">
        <StaticImage
          src="../images/logo.png"
          width={40}
          className="logo-image"
        ></StaticImage>
        <Link to={"/"}>Old Court Wedding Company</Link>
      </div>

      <ul className="menu-items">
        <li className="menu-item">
          <Link to={"/"}>Home</Link>
        </li>
        <li className="menu-item">
          <Link to={"/gallery"}>Gallery</Link>
        </li>
        <li className="menu-item">
          <Link to={"/pricing"}>Pricing</Link>
        </li>
        <li className="menu-item">
          <Link to={"/contact"}>Contact Us</Link>
        </li>
      </ul>
    </nav>
  )
}

const Nav = () => {
  const { width } = useWindowSize()
  return width < 769 ? <MobileNav /> : <DesktopNav />
}

export default Nav
